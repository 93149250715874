@font-face {
  font-family: "Gilroy";
  src: url("/assets/studyprime/fonts/out/Gilroy-Light.eot");
  src: url("/assets/studyprime/fonts/out/Gilroy-Light.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/studyprime/fonts/out/Gilroy-Light.svg#Gilroy-Light")
      format("svg"),
    url("/assets/studyprime/fonts/out/Gilroy-Light.ttf") format("truetype"),
    url("/assets/studyprime/fonts/out/Gilroy-Light.woff") format("woff"),
    url("/assets/studyprime/fonts/out/Gilroy-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("/assets/studyprime/fonts/out/Gilroy-ExtraBold.eot");
  src: url("/assets/studyprime/fonts/out/Gilroy-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/studyprime/fonts/out/Gilroy-ExtraBold.svg#Gilroy-ExtraBold")
      format("svg"),
    url("/assets/studyprime/fonts/out/Gilroy-ExtraBold.ttf") format("truetype"),
    url("/assets/studyprime/fonts/out/Gilroy-ExtraBold.woff") format("woff"),
    url("/assets/studyprime/fonts/out/Gilroy-ExtraBold.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("/assets/studyprime/fonts/out/OpenSans-Light.eot");
  src: url("/assets/studyprime/fonts/out/OpenSans-Light.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/studyprime/fonts/out/OpenSans-Light.svg#OpenSans-Light")
      format("svg"),
    url("/assets/studyprime/fonts/out/OpenSans-Light.ttf") format("truetype"),
    url("/assets/studyprime/fonts/out/OpenSans-Light.woff") format("woff"),
    url("/assets/studyprime/fonts/out/OpenSans-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("/assets/studyprime/fonts/out/OpenSans-Regular.eot");
  src: url("/assets/studyprime/fonts/out/OpenSans-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/studyprime/fonts/out/OpenSans-Regular.svg#OpenSans-Regular")
      format("svg"),
    url("/assets/studyprime/fonts/out/OpenSans-Regular.ttf") format("truetype"),
    url("/assets/studyprime/fonts/out/OpenSans-Regular.woff") format("woff"),
    url("/assets/studyprime/fonts/out/OpenSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("/assets/studyprime/fonts/out/OpenSans-SemiBold.eot");
  src: url("/assets/studyprime/fonts/out/OpenSans-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/studyprime/fonts/out/OpenSans-SemiBold.svg#OpenSans-SemiBold")
      format("svg"),
    url("/assets/studyprime/fonts/out/OpenSans-SemiBold.ttf") format("truetype"),
    url("/assets/studyprime/fonts/out/OpenSans-SemiBold.woff") format("woff"),
    url("/assets/studyprime/fonts/out/OpenSans-SemiBold.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("/assets/studyprime/fonts/out/OpenSans-Bold.eot");
  src: url("/assets/studyprime/fonts/out/OpenSans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/studyprime/fonts/out/OpenSans-Bold.svg#OpenSans-Bold")
      format("svg"),
    url("/assets/studyprime/fonts/out/OpenSans-Bold.ttf") format("truetype"),
    url("/assets/studyprime/fonts/out/OpenSans-Bold.woff") format("woff"),
    url("/assets/studyprime/fonts/out/OpenSans-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("/assets/studyprime/fonts/out/OpenSans-ExtraBold.eot");
  src: url("/assets/studyprime/fonts/out/OpenSans-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/studyprime/fonts/out/OpenSans-ExtraBold.svg#OpenSans-ExtraBold")
      format("svg"),
    url("/assets/studyprime/fonts/out/OpenSans-ExtraBold.ttf")
      format("truetype"),
    url("/assets/studyprime/fonts/out/OpenSans-ExtraBold.woff") format("woff"),
    url("/assets/studyprime/fonts/out/OpenSans-ExtraBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
