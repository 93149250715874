
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~swiper/swiper-bundle.min.css";
@import "~katex/dist/katex.min.css";
@import "./theme/fonts.scss";
@import "./theme/variables.scss";
@import "./theme/mixinsimple.scss";
@import "./_base";

@import 'codemirror/lib/codemirror';

.math_block {
  text-align: left;

  @media #{$isMobile} {
    padding-left: 20px;
  }
}

@media print {
  div.dontwaste {
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: black;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

.grecaptcha-badge {
  visibility: collapse !important;
}

body {

}

button:focus {
  outline: none !important;
}

// Overrides unintended margin from _reboot.scss
.mdc-form-field > label {
  margin-bottom: unset;
}
