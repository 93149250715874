@mixin easeTransition() {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

@mixin content-header {
  text-transform: uppercase;
  font-weight: lighter;
  letter-spacing: 1.5px;
  font-size: 1.8rem;
}

@mixin scroll() {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #386eaf;
    outline: 1px solid #386eaf;
  }
}

@mixin overlay() {
  background: var(--white);
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}
