@mixin editorStyles() {
  .tab {
    margin-left: 100px;
    float: inside;
  }

  .text-framed {
    float: left;
    border: solid 2px #333;
    padding: 5px;
    border-radius: 3px;
  }
}

li {
  margin-top: 8px;
  margin-bottom: 8px;
}
