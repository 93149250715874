@use './typography';
@use 'media';

$mobileBreakpoint: 768px;
$smallMobileBreakPoint: 450px;
$mobile: 'screen and (max-width: #{$mobileBreakpoint})';
$smallMobile: 'screen and (max-width: #{$smallMobileBreakPoint})';
$desktop: 'screen and (min-width: #{$mobileBreakpoint + 1})';
$contentWidth: 1142px;
$tabletBreakPoint: 1024px;
$tablet: 'screen and (min-width: #{$mobileBreakpoint + 1}) and (max-width: #{$tabletBreakPoint})';
$beforeTabletBreakPoint: 'screen and (max-width: #{$tabletBreakPoint})';
$afterTabletBreakPoint: 'screen and (min-width: #{$tabletBreakPoint + 1})';

@mixin textarea {
  border: 2px solid var(--field-border-color-default-2);
  background-color: var(--page-background);
  border-radius: 12px;
  padding: 10px;
  line-height: 24px;
  border-radius: var(--border-radius);
}

// TODO: deprectae after refactoring finished
.btn-common {
  cursor: pointer;
  text-align: center;
  border-radius: var(--border-radius);
  border: none;
  box-shadow: 0 0 0 #00000033;
  transition: box-shadow 0.3s cubic-bezier(0, 0.5, 0.5, 1);
  &:hover {
    box-shadow: 0 2px 6px #00000033;
    transition: none;
  }
}

/* @mixins */
@mixin commonInput() {
  line-height: 20px;
  padding: 20px 12px;
  cursor: pointer;
}

@mixin outline($color) {
  background: transparent;
  border: var(--border-width) solid var($color);
  color: var($color);
}

@mixin commonSelect() {
  display: block;
  .mat-form-field {
    @include commonInput;
    display: block;
    border: var(--border-width) solid var(--field-border-color-default-1);
    border-radius: var(--border-radius);
    background-color: var(--light);

    .mat-form-field-wrapper {
      padding: 0;
    }

    .mat-form-field-infix {
      padding: 0;
      margin: 0;
      border: none;
    }
    .mat-select-trigger {
      display: block;
    }

    .mat-form-field-underline {
      display: none;
    }
    .mat-form-field-label {
      font-family: 'Gilroy', sans-serif;
      font-weight: 500;
      color: var(--dark);
      text-align: center;
    }
    &.mat-focused .mat-form-field-label {
      color: var(--primary);
      opacity: 1;
    }

    .mat-select-arrow {
      color: var(--primary);
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid;
      border-radius: 4px;
    }
  }
}
@mixin commonButton() {
  --color: var(--light);
  padding: 14px;
  background: var(--btn-bg-color-default);
  color: var(--color);
  text-align: center;
  border-radius: var(--border-radius);
  font-weight: 400;

  box-shadow: 0 0 0 #00000033;
  transition: box-shadow 0.3s cubic-bezier(0, 0.5, 0.5, 1);
  border: none;

  &:hover {
    transition: none;
    box-shadow: 0 2px 6px #00000033;
  }

  &.light {
    --color: var(--dark);
    background: transparent;
    border: var(--border-width) solid var(--color);
    color: var(--color);
  }

  &.large {
    @include typography.paragraphSizeLarge();
    padding: 17px 12px;
  }
  &.small {
    padding: 4px 8px;
  }

  &.primary {
    --color: var(--primary);
    color: var(--light);
    background: linear-gradient(
      135deg,
      var(--primary) 0%,
      var(--primary-dark) 100%
    );
    &.outline {
      @include outline(var(--color));
    }
  }

  &.accent {
    --color: var(--accent);
    --gradient-from: var(--accent);
    --gradient-to: var(--accent-very-light);
    --angle: 0;

    background: linear-gradient(
      var(--angle, 128deg),
      var(--gradient-from, var(--accent-light)) 0%,
      var(--gradient-to, var(--accent-very-light)) 100%
    );
    color: var(--light);
    &.flipped {
      --angle: 180deg;
    }
    &.outline {
      @include outline(var(--color));
    }
  }

  &.warning {
    --color: var(--warning);
    color: var(--light);
    border-color: var(--warning);
    background: linear-gradient(
      135deg,
      var(--warning) 0%,
      var(--warning-dark) 100%
    );
    &.outline {
      --color: var(--warning-dark);
      @include outline(var(--color));
    }
  }

  &.danger {
    --color: var(--danger);
    color: var(--light);
    border-color: var(--danger);
    background: linear-gradient(
      135deg,
      var(--danger) 0%,
      var(--danger-dark) 100%
    );
    &.outline {
      @include outline(var(--color));
    }
  }

  &.success {
    --color: var(--green);
    color: var(--light);
    border-color: var(--green);
    background: linear-gradient(
      135deg,
      var(--green) 0%,
      var(--green-dark) 100%
    );
    &.outline {
      @include outline(var(--color));
    }
  }

  &.outline {
    background: transparent;
    border: var(--border-width) solid var(--color);
    color: var(--color);
  }

  &.flat {
    background: var(--accent);
    border: var(--border-width) solid var(--accent);
  }

  &.disabled {
    opacity: 0.8;
    pointer-events: none;
  }
}

@mixin expandVideoButton {
  margin: 10px 0 4px;
  line-height: 1.8;
  font-size: 14px;
  @include media.sm-screen-down {
    margin-top: 10px;
    margin-bottom: 12px;
    font-size: 10px;
    padding: 0 17px;
  }
}
