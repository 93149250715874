@use "./media.scss";

/**
* ALL !important needed to override main selectors #page-container > .* ... will be removed after global styles refactoriung
*/
@mixin headingFontFamily {
  font-family: "Gilroy", sans-serif;
}

@mixin headingFontFamilySecondary {
  font-family: "Montserrat", sans-serif;
}
// Замінити font-stack-bolt
@mixin test {
  font-size: 16px;
}

@mixin headingFont {
  @include headingFontFamily;
  color: var(--dark);
  margin: 0;
  line-height: 1.2;
  @include media.sm-screen-down {
    text-align: center;
  }
}

@mixin headingLight {
  font-family: "OpenSans", sans-serif;
  font-weight: 300;
}

@mixin heading {
  font-weight: 500;
}

@mixin headingSize1 {
  font-size: 52px;
  @include media.sm-screen-down {
    font-size: 32px;
  }
}

@mixin headingSize2 {
  font-size: 50px;
  @include media.sm-screen-down {
    font-size: 32px;
  }
}

@mixin headingSize3 {
  font-size: 40px;
  @include media.sm-screen-down {
    font-size: 20px;
  }
}

@mixin headingSize4 {
  font-size: 30px;
  @include media.sm-screen-down {
    font-size: 24px;
  }
}

@mixin headingSize5 {
  font-size: 22px;
  &.light {
    font-size: 20px;
  }
}

@mixin headingSize6 {
  font-size: 17px;
}

@mixin headingSize7 {
  font-size: 16px;
}

@mixin heading1 {
  @include headingFont;
  @include headingSize1;
  @include fontWeightRegular;
}

@mixin heading1-landing-page {
  @include heading1;
  font-size: 50px;
  @include media.sm-screen-down {
    font-size: 30px;
  }
}

@mixin heading2 {
  @include headingFont;
  @include headingSize2;
  @include fontWeightRegular;
}

@mixin heading3 {
  @include headingFont;
  @include headingSize3;
  @include fontWeightRegular;
}

@mixin heading4 {
  @include headingFont;
  @include headingSize4;
  @include fontWeightRegular;
}

@mixin heading5 {
  @include headingFont;
  @include headingSize5;
  @include fontWeightRegular;
}

@mixin heading6 {
  @include headingFont;
  @include headingSize6;
  @include fontWeightRegular;
}

@mixin heading7 {
  @include headingFont();
  @include headingSize7();
  @include fontWeightRegular;
}

@mixin headingUppercased6 {
  text-transform: uppercase;
  font-weight: lighter;
  letter-spacing: 1.5px;
  @include headingSize6();
}

@mixin paragraphFont {
  font-family: "OpenSans", Arial, Helvetica, sans-serif;
}

@mixin paragraphBold {
  font-weight: 800;
}

@mixin paragraphSizeExtraLarge {
  font-size: 18px;
}

@mixin paragraphSizeLarge {
  font-size: 17px;
}

@mixin paragraphSizeDefault {
  font-size: 16px;
}

@mixin paragraphSizeSmall {
  font-size: 14px;
}

@mixin openSansHeading {
  font-family: "OpenSans", sans-serif;
}

@mixin openSansHeadingSize4 {
  @include openSansHeading;
  font-size: 17px;
  @include media.sm-screen-down {
    font-size: 14px;
  }
}

@mixin fontWeightBold {
  font-weight: 700;
}

@mixin fontWeightRegular {
  font-weight: 500;
}

@mixin fontWeightDefault {
  font-weight: 400;
}

@mixin fontWeightLight {
  font-weight: 300;
}

@mixin textEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin sectionHeading {
  @include heading5;
  margin-bottom: 30px;
  text-align: left;
}

@mixin sectionHeadingDeviding {
  @include sectionHeading();
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 74px;
    height: 3px;
    border-radius: 12px;
    background-color: var(--primary);
    position: absolute;
    left: 0;
    bottom: -10px;
  }
}

@mixin dividingAccent {
  position: relative;

  &::before {
    content: "";
    width: 36px;
    height: 3px;
    border-radius: 8px;
    background-color: var(--accent);
    display: block;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}

@mixin paragraphRegular {
  @include paragraphFont();
  @include paragraphSizeDefault();

  @include media.sm-screen-down {
    @include paragraphSizeLarge();
  }
}
