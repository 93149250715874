@use "layout";
@use "typography";
@use "media";
@import "controls-mixins";
@import "editor.styles.scss";
@import "./theme/mixin-forms";
@import "./theme/variables";

@include editorStyles();

:root {
  @include root-variables();
}

* {
  box-sizing: border-box;
}

html {
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-tap-highlight-color: rgba(black, 0);
  color: var(--text-default);

  line-height: 1.15;
}

body {
  @include scroll();
  @include typography.paragraphFont();
  background-color: var(--primary-background);
  margin: 0;
  line-height: 1.5;
}

img {
  @media print {
    page-break-inside: avoid;
  }
}

a {
  text-decoration: none;
  color: var(--primary-intensive);
}

h1 {
  @include typography.heading1;
}

h2 {
  @include typography.heading2;
}

h3 {
  @include typography.heading3;
}

h4 {
  @include typography.heading4;
}

h5 {
  @include typography.heading5;
}

h6 {
  @include typography.heading6;
}

// Global styles
.ecoreps-app-root {
  max-width: 100vw;
  .app-content {
    // To cut off images on BG
    overflow-x: hidden;
    justify-content: center;
  }
}

.part {
  position: relative;
  margin: auto 0;
  padding: 70px 0;
  @include media.sm-screen-up {
    padding-top: 20px;
  }

  .background-part {
    background-repeat: no-repeat;
    position: absolute;
    z-index: 0;
    pointer-events: none;
    background-size: contain;
  }
  > .content {
    position: relative;
    z-index: 1;
    grid-column: 2;
    > .image {
      img {
        max-width: 100%;
      }
    }
  }

  &::before {
    content: "";
    width: 36px;
    height: 3px;
    border-radius: 8px;
    background-color: var(--accent);
    display: block;
    position: absolute;
    top: 0;
    left: calc(50% - 18px);
    z-index: 1;
  }

  &.no-divider {
    &::before {
      content: none;
      display: none;
    }
  }

  > .content {
    > .part-divider {
      position: relative;

      &::before {
        content: "";
        width: 36px;
        height: 3px;
        border-radius: 8px;
        background-color: var(--accent);
        display: block;
        position: absolute;
        top: -20px;
        left: calc(50% - 10px);
        z-index: 1;

        @media #{$desktop} {
          left: 0;
        }
      }
    }
  }
}

.ecoreps-app-root {
  &.offline-page {
    background-color: white;

    .part {
      > .content {
        @include layout.contentWrapper;
      }

      @media #{$desktop} {
        display: grid;
        grid-template-columns: 1fr auto 1fr;

        > .content {
          width: 100vw;
          max-width: var(--content-width);
          grid-column: 2;
          justify-self: center;
        }

        &.overflow-layout {
          > .content {
            grid-column: 2;
            max-width: var(--content-wrapper-width);
          }
        }
      }
    }

    .nav.part,
    ~ * {
      display: none;
    }
  }
}

.btn {
  @include commonButton;
}

.ecoreps-app-root {
  .reviews.part {
    .reviews-carousel {
      .course-name {
        display: none !important;
      }
    }

    .review-view {
      h2 {
        font-size: 26px;
      }

      h3 {
        font-size: 18px;
        color: var(--primary);
        text-align: left;
      }

      .close-btn {
        transform: none;
        color: var(--accent);
        &::before {
          display: none;
        }
      }
    }
  }

  .wobbly-wobble {
    > .content {
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: calc(0px - var(--top-bar-height));
        background-repeat: no-repeat;
        z-index: -100;
        pointer-events: none;
        background-position: -300px top;
      }

      &::before {
        height: 600px;
        background-image: url("../assets/studyprime/svg/bgtop.svg");
        width: 1288px;
        background-position: left -10px;
        right: -450px;

        @media #{$mobile} {
          height: 400px;
          width: 100vw;
          background-image: url("../assets/studyprime/svg/bgtop-4.svg");
          right: 0;
          background-size: contain;
          background-position: right top;
        }
      }

      &::after {
        --width: calc((100vw - var(--content-width)) / 2);
        height: 569px;
        right: calc(-450px - var(--width));
        width: var(--width);
        background-color: #ecf5fe;
      }
    }
  }
}

@include matSelectOverwright();

.textarea {
  @include textarea();
}

.bottom-sticky {
  position: fixed;
  width: 100%;
  height: var(--sticky-footer-height);
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 8px 20px;
  z-index: 100;
  background-color: var(--page-background);
  box-shadow: 0 -4px 8px #00000022;
  border-radius: 15px 15px 0 0;
}
