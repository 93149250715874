@use "theme";
@import "mixinsimple";
// !! SCSS VARIABLE IS DEPRECATED KEPT HERE ONLY FOR BACKWARD
// !! COMPATIBILITY EACH TIME YOU SEE IT'S USED SOME WHERE PLEASE UPDATE BY CSS VAR

$font-material-icons: "Material+Icons";
// Breite der Haupseite (Inhalt)
$globalMaxWidth: 980px !default;
// $globalMinWidth: 960px !default;
$parallaxHeight: 400px;

// Mobile media query
$isMobile: "screen and (max-width: 500px), screen and (max-width: 750px) and (orientation: landscape)";

$content-width: 1280px;

//Strukturelemente Variablen
$colorTipp: #c2d5eb !default;
$colorTipp2: #c8e3aa !default;
$colorNerdKnowledge: #f1c596 !default;
$colorRules: #fbb1b2 !default;
$colorDefinition: #f4f4f4 !default;
$colorStepsBorder: #979797;
$globalBorderRadius: 5px;
$colorBoxInnerHeading: #444c54;

$yellow: #f8e742;
$red: #dc3545;
$orange: #fd7e14;

@mixin root-variables() {
  @include theme.main();
  --spacing: 10px;
  --page-top: 0;
  --global-min-width: 960px;
  --side-bar-width: 300px;
  --side-bar-font-size: 15px;
  --content-wrapper-width: 1604px;
  --content-width: #{$contentWidth};
  --top-bar-height: 60px;
  --page-padding: 12px;
  --border-radius: 6px;
  --border-width: 2px;
  --surface-bg: #FFF;
  --sticky-footer-height: 60px;
  --z-index-floating-controls: 999;
  --z-index-navigation: 100;
  --z-index-bottom-bar: 90;
  --z-index-popup-overlay: 10000;
  --font-size-large: 18px;
  --font-size-regular: 16px;
  --font-size-default: 14px;
  --font-size-medium: 12px;
  --font-size-small: 10px;
  --admin-container-spacing: 15px;
  --admin-items-spacing: 8px;
  --overlay-z-index: 1000;
  @media #{$desktop} {
    --top-bar-height: 90px;
    --page-padding: 16px;
  }
}
