@mixin mat-select-simple {
  mat-form-field {
    width: 100%;

    &.ng-dirty {
      .mat-form-field-label-wrapper {
        display: none;
      }
    }

    &.mat-form-field-appearance-legacy {
      .mat-form-field-label {
        color: rgba(0, 0, 0, 0.34);
        @include typography.paragraphFont();
      }

      .mat-form-field-underline {
        display: none;
        background-color: var(--field-border-color-default-2);
        width: calc(100% - 8px);
        margin-left: 4px;
      }
    }

    .mat-form-field-wrapper .mat-form-field-flex {
      cursor: pointer;
      border: 1px solid var(--field-border-color-default-2);
      border-radius: 5px;
      background-color: var(--page-background);

      .mat-form-field-infix {
        border-top: 11px solid transparent;
        padding: 0 0 14px 0;

        .mat-select {
          font-size: 16px !important;
          @include typography.paragraphFont();

          .mat-select-trigger {
            padding: 0 15px 0 10px;
            .mat-select-arrow-wrapper {
              vertical-align: baseline;

              .mat-select-arrow {
                color: #386eaf;
              }
            }
          }
        }

        .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
          color: black;
        }
      }
    }
  }
}

@mixin matSelectOverwright {
  .mat-select-panel-wrap {
    .mat-option {
      font-size: 16px !important;
      background: var(--page-background);
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      color: black;
      font-weight: normal;
      transition: all ease 0.3s;

      &:hover {
        background: #e8f3fa;
      }
    }

    .mat-option.mat-selected.mat-active {
      background: #e8f3fa;
      border-left: 3px solid #386eaf;
      color: black;
    }

    .mat-select-panel {
      @include scroll();
    }
  }
}
