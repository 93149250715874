@mixin main() {
  --primary-background: #fff;
  --white-dark-background: #fcfcfc;
  --dark: #191917;
  --text-default: #212529;
  --light: #ffffff;
  --dark-gray: #707070;
  --secondary: #707070;
  --secondary-light: #a2a2a2;
  --secondary-dark: #4a4a4a;
  --field-border-color-default-1: #e6e6e6;
  --btn-bg-color-default: var(--secondary-light);
  --btn-bg-color-disabled: var(--secondary-light);
  --field-border-color-default-2: #bbbbbb;
  --primary-lighten: #439bb1;
  --primary-tint: #699dee11;
  --primary-very-light: #e7f3fe;
  --primary-light: #a5bfe8;
  --primary: #699dee;
  --primary-dark: #5781c3;
  --primary-darken: #3b77bf;
  --primary-intensive: #007bff;
  --primary-fill: #3984c8;
  --accent: #fd4f00;
  --accent-light: #f47052;
  --accent-very-light: #ff8901;
  --warning: #fecc37;
  --warning-dark: #eeb404;
  --danger: #dc3545;
  --danger-dark: #a41826;
  --divider-color: #d2dce9;
  --divider-color-light: #e6e6e6;
  --page-background: #f4f4f4;
  --green: #0fc90f;
  --green-dark: #169716;
  --loading-screen-bg: #fafafa;
  --star-rating-background: #ffd700;
  --sidebar-hover-color: var(--primary-fill);
  --sidebar-active-color: var(--primary-fill);
  --sidebar-text-color: #1e4969;
  --sidebar-text-color-hover: var(--light);
  --success: var(--green);
  --success-light: #3fb362;
  --success-lighten: rgb(113, 208, 142);
  --disable-color: #aaaaaa;
  --action-zone-border: #aaaaaa;
  --action-zone-bg: #eeeeee;
}
